@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.session-card {
  display: none;
  &--visible {
    display: block;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__inner {
    display: flex;
    background: light.$surface-primary-default;
  }

  &__button {
    background: light.$surface-primary-default;
    border: none;
    outline: none;

    padding: spacing.$s;
    width: 100%;
    text-align: left;

    border-left: 8px solid light.$ge-signal-neutral;

    &--orange {
      border-left-color: light.$ge-signal-warning;
    }

    &--green {
      border-left-color: light.$ge-signal-success;
    }

    &--red {
      border-left-color: light.$ge-signal-error;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__error {
    color: light.$ge-signal-error;
    word-break: break-word;
  }

  &__options {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__options-button, &__info-modal-button {
    background: light.$surface-primary-default;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 spacing.$s;
  }

  &__inner-top {
    display: flex;
    gap: spacing.$xs;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__time {
    color: light.$on-surface-primary-alternate;
  }

  &__info-modal-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: spacing.$s;
  }

  &__info-modal, &__info-modal-inner {
    > * {
      margin-bottom: spacing.$m;
    }
  }
}
